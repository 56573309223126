import { Box } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { CardProduct } from "../card/CardProduct";

export const ProductCarousel = ({ relacionado }) => {
	return (
		<Carousel
			interval={1000}
			duration={250}
			animation="slide"
			autoPlay={false}
			navButtonsAlwaysVisible
		>
			{relacionado.map((producto, index) => (
				<Box
					key={index}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CardProduct producto={producto} />
				</Box>
			))}
		</Carousel>
	);
};
