import React from 'react';
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { dmartinaTheme } from "./theme/dmartinaTheme";
import { AppLayout } from "./layout/AppLayout";
import { AppRouter } from "./router/AppRouter";
import "animate.css";
import "./styles.css";
import { Toaster } from "react-hot-toast";

const container = document.getElementById("root");
const root = createRoot(container);

if ("serviceWorker" in navigator) {
	navigator.serviceWorker.ready.then((registration) => {
		registration.unregister();
	});
}

root.render(
	<React.StrictMode>
		{/* Provider SEO */}
		<HelmetProvider>
			{/* Provider Redux */}
			<Provider store={store}>
				{/* Provider Theme */}
				<ThemeProvider theme={dmartinaTheme}>
					{/* Provider RUTAS */}
					<BrowserRouter>
						{/* Layout APP */}
						<AppLayout>
							<CssBaseline />
							{/* Rutas y renderizado */}
							<AppRouter />
							<Toaster />
						</AppLayout>
					</BrowserRouter>
				</ThemeProvider>
			</Provider>
		</HelmetProvider>
	</React.StrictMode>
);
