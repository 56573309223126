import { Box, IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { changeCurrentPath } from "../../slices/location/locationSlice";
import ArrowIcon from "@mui/icons-material/ArrowBackRounded";
import { ContactCard } from "../../components/ui";
import { getContactoData } from "../../slices/contacto/contactoSlice";
import { PageSEO } from "../../components/seo/PageSEO";
import {
	categoryInitialState,
	inputInitialState,
} from "../../slices/search/searchSlice";

const ContactoPage = () => {
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { seo, descripcion } = useSelector((state) => state.contactoReducer);
	const navigateBack = () => {
		navigate("/");
	};
	useEffect(() => {
		dispatch(changeCurrentPath(pathname.split("")));
	}, []); //eslint-disable-line

	useEffect(() => {
		dispatch(categoryInitialState());
		dispatch(inputInitialState());
		dispatch(getContactoData());
	}, []); //eslint-disable-line

	useEffect(() => {
		dispatch(categoryInitialState());
		dispatch(inputInitialState());
	}, []); //eslint-disable-line

	return (
		<>
			<PageSEO seo={seo} />
			<Box
				sx={{
					width: { xs: "100%" },
					px: { xs: 1 },
					display: { sm: "flex" },
					justifyContent: { sm: "center" },
				}}
				className="animate__animated animate__fadeIn"
			>
				<Box
					sx={{
						display: { sm: "flex" },
						flexDirection: { sm: "column" },
						width: { sm: "70%", lg: "60%" },
						height: { sm: "100%" },
					}}
				>
					<Box
						display="flex"
						sx={{
							my: { xs: 1 },
						}}
					>
						<IconButton
							onClick={navigateBack}
							sx={{
								width: { sm: 48, lg: 134 },
								py: { sm: 1.5 },
								backgroundColor: "#fff",
								borderRadius: "4px",
								boxShadow:
									"0 1px 1px 0 rgba(0,0,0,.20), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
								display: { sm: "flex" },
								alignItems: { sm: "center" },
								justifyContent: { sm: "center" },
								cursor: "pointer",
							}}
						>
							<ArrowIcon />
						</IconButton>
						<Box
							sx={{
								backgroundColor: "#fff",
								borderRadius: "4px",
								boxShadow:
									"0 1px 1px 0 rgba(0,0,0,.20), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
								display: { xs: "flex" },
								alignItems: { xs: "center" },
								flexGrow: { xs: 1 },
								ml: { xs: 1, lg: 2 },
								pl: { xs: 2 },
							}}
						>
							<Typography>Contacto</Typography>
						</Box>
					</Box>

					<ContactCard descripcion={descripcion} />
				</Box>
			</Box>
		</>
	);
};

export default ContactoPage;
