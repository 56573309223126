import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	Typography,
} from "@mui/material";
import BazarIcon from "@mui/icons-material/CheckroomRounded";
import BlanqueriaIcon from "@mui/icons-material/BedRounded";
import CampingIcon from "@mui/icons-material/CabinRounded";
import ElectroIcon from "@mui/icons-material/KitchenRounded";
import VariosIcon from "@mui/icons-material/MoreVertRounded";

const categoriesIcons = [
	<BazarIcon />,
	<BlanqueriaIcon />,
	<CampingIcon />,
	<ElectroIcon />,
	<VariosIcon />,
];

export const SidebarCategories = ({
	categorias,
	categorySelected,
	toggleDrawer,
	categoryID,
}) => {
	return (
		<>
			{categorias.map((categoria, index) => (
				<ListItem
					key={index}
					onClick={toggleDrawer(false)}
					onKeyDown={toggleDrawer(false)}
					sx={{ px: 0, py: 0.5 }}
				>
					<ListItemButton
						onClick={() =>
							categorySelected(categoria.cate_id, categoria.cate_nombre)
						}
						sx={{
							background: categoryID === categoria.cate_id && "#d0cd9466",
							boxShadow:
								categoryID === categoria.cate_id && "0 3px 5px 0 #00000033",
							pl: 4,
						}}
					>
						<ListItemIcon>{categoriesIcons[index]}</ListItemIcon>
						<Typography sx={{ fontSize: { xs: "body2", lg: "" } }}>
							{categoria.cate_nombre}
						</Typography>
					</ListItemButton>
				</ListItem>
			))}
		</>
	);
};
