import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Footer, Navbar, Sidebar } from "../components/ui";
import { ToastAlert } from "../components/ui/toast/ToastAlert";
import { getCategorias } from "../slices/categorias/categoriasSlice";
import { changeCurrentURL } from "../slices/location/locationSlice";
import {
	categoryInitialState,
	inputInitialState,
} from "../slices/search/searchSlice";
import { UserCheck } from "../helper/userCheck.js";
export const AppLayout = ({ children }) => {
	// Funcion para saber si el usuario entro alguna vez o no
	UserCheck()
	const dispatch = useDispatch();
	// Estado para mostrar u ocultar el input
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	// Estado para mostrar u ocultar el sidebar
	const [isDrawerOpen, setIsDrawerOpen] = useState({
		left: false,
	});
	// Categorias traidas desde la API
	const { categorias } = useSelector((state) => state.categoriasReducer);
	const { currentPath } = useSelector((state) => state.locationReducer);
	// Input de busqueda y categorias -> filtros de busqueda
	const { inputSearch, categoryID } = useSelector(
		(state) => state.searchReducer
	);

	// Estado de toast para mostrar alerta si el producto seleccionado no existe en DB
	const { isToastVisible } = useSelector((state) => state.toastReducer);

	// Funcion encargada de mostrar u ocultar el input de busqueda
	const searchItem = () => {
		// Dispara una funcion que borra la categoria que haya guardado
		dispatch(categoryInitialState());
		// Dispara una funcion que borra el input que haya guardado
		dispatch(inputInitialState());
		// Cambia el estado boolean para mostrar u ocultar el input
		setIsSearchOpen(!isSearchOpen);
	};

	// Funcion encargada de mostrar u ocultar el sidebar
	const toggleDrawer = (open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		// Cambia el estado que muestra u oculta el sidebar
		setIsDrawerOpen({ ...isDrawerOpen, left: open });
	};

	// Dispara un efecto on render que trae todas las categorias desde el backend
	useEffect(() => {
		if (currentPath.length > 1) {
			return;
		}
		dispatch(getCategorias());
	}, []); //eslint-disable-line

	useEffect(() => {
		dispatch(changeCurrentURL(window.location.href));
	}, [window.location.href]); //eslint-disable-line

	return (
		<>
			{isToastVisible && <ToastAlert />}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					height: "100vh",
				}}
			>
				{/* NAVBAR */}
				<Navbar
					isSearchOpen={isSearchOpen}
					searchItem={searchItem}
					toggleDrawer={toggleDrawer}
					inputSearch={inputSearch}
				/>

				<Grid
					container
					sx={{ width: { xs: "100%" } }}
					display="flex"
					flexGrow={1}
				>
					<Grid item lg={2}>
						{/* SIDEBAR */}
						<Sidebar
							toggleDrawer={toggleDrawer}
							isDrawerOpen={isDrawerOpen}
							categorias={categorias}
							categoryID={categoryID}
							setIsSearchOpen={setIsSearchOpen}
						/>
					</Grid>

					{/* PAGINAS */}
					<Grid
						item
						lg={10}
						sx={{
							width: { xs: "100%" },
						}}
					>
						{children}
					</Grid>
				</Grid>

				{/* FOOTER */}
				<Footer />
			</Box>
		</>
	);
};
