import { Box } from "@mui/material";
import { categorySearch } from "../../../slices/search/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import { SidebarMobile, SidebarDesktop, SidebarList } from "./";
import { useEffect } from "react";
import { categoryFilter } from "../../../slices/productos/productosSlice";
import { changeCurrentPage } from "../../../slices/pagination/paginationSlice";
import { useLocation, useNavigate } from "react-router-dom";

export const Sidebar = ({
	toggleDrawer,
	isDrawerOpen,
	categorias,
	categoryID,
	setIsSearchOpen,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { currentPath } = useSelector((state) => state.locationReducer);

	// Funcion que guarda el ID de la categoria seleccionada para filtrar los productos
	const categorySelected = (id, name) => {
		dispatch(categorySearch({ id, name }));
		dispatch(changeCurrentPage(1));
		navigate("/");
	};

	// Dispara un efecto que filtra los productos por el ID enviado
	useEffect(() => {
		dispatch(categoryFilter(categoryID));
	}, [categoryID]); //eslint-disable-line

	return (
		<Box display="block" sx={{ height: { lg: "100%" }, width: { lg: "100%" } }}>
			<SidebarMobile isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer}>
				<SidebarList
					categorySelected={categorySelected}
					categoryID={categoryID}
					anchor="left"
					categorias={categorias}
					toggleDrawer={toggleDrawer}
					currentPath={currentPath}
					pathname={pathname}
					setIsSearchOpen={setIsSearchOpen}
				/>
			</SidebarMobile>

			<SidebarDesktop>
				<SidebarList
					categorySelected={categorySelected}
					categoryID={categoryID}
					categorias={categorias}
					toggleDrawer={toggleDrawer}
					currentPath={currentPath}
					pathname={pathname}
					setIsSearchOpen={setIsSearchOpen}
				/>
			</SidebarDesktop>
		</Box>
	);
};
