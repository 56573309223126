import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosUrl } from "../../api/axiosConfig";
import { API } from "../../api/dmartinaAPI";

const initialState = {
	seo: {},
	descripcion: "",
	titulo: "",
};

export const getAcercaData = createAsyncThunk("acerca/getAcerca", async () => {
	const { data } = await axiosUrl.get(API.acerca);
	return data;
});

const acercaSlice = createSlice({
	name: "acerca",
	initialState,
	reducers: {},
	extraReducers: {
		[getAcercaData.fulfilled]: (state, { payload }) => {
			return {
				...state,
				seo: payload.data.seo,
				descripcion: payload.data.descripcion,
				titulo: payload.data.titulo,
			};
		},
	},
});

export default acercaSlice.reducer;
