import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import { ContactMap } from "../map/ContactMap";
import QuestionIcon from "@mui/icons-material/ContactSupportRounded";

export const ContactCard = ({ descripcion }) => {
	return (
		<Card
			sx={{
				backgroundColor: "#d0cd9466",
				my: { xs: 2 },
			}}
		>
			<CardContent
				sx={{
					minHeight: 160,
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				{/* Titulo */}
				<Box
					sx={{
						display: { xs: "flex" },
						alignItems: { xs: "center" },
						justifyContent: { xs: "center" },
						mb: { xs: 2 },
					}}
				>
					<QuestionIcon sx={{ width: { xs: 48 }, height: { xs: 48 } }} />
					<Typography
						component="h1"
						sx={{
							fontSize: { xs: "h5" },
							fontWeight: { xs: 600 },
							pl: { xs: 2 },
							mr: { xs: 3 },
						}}
					>
						Contacto
					</Typography>
				</Box>
				{/* Descripcion */}
				<Box sx={{ my: 2 }}>
					<Typography
						variant="p"
						component="p"
						fontSize={14}
						textAlign="center"
						sx={{
							fontSize: { xs: "body2" },
							fontWeight: { xs: 500 },
							textAlign: { xs: "left" },
						}}
						dangerouslySetInnerHTML={{ __html: descripcion }}
					/>
				</Box>
				<Divider sx={{ my: 3 }} />

				{/* MAPA */}
				<ContactMap />
			</CardContent>
		</Card>
	);
};
