import { WhatsApp } from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dmartinaAPI } from "../../../api/dmartinaAPI";
import placeholder from "../../../images/placeholder.svg";
import { getProducto } from "../../../slices/producto/productoSlice";
import { userTracker } from "../../../helper/userClickTracker";

export const CardProduct = ({ producto }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const url = window.location.href;

	const navigateToProduct = () => {
		userTracker(url, producto.prod_nombre, "Click en Detalles")
		dispatch(getProducto(producto.prod_slug)).then(({ payload }) => {
			sessionStorage.setItem("dmartinaProducto", JSON.stringify(payload.data));
			navigate(`/producto/${producto.prod_slug}`);
			window.scrollTo(0, 0);
		});
	};

	const sendWhatsapp = () => {
		userTracker(url, producto.prod_nombre, "Click en WhatsApp")
		window.open(
			`https://api.whatsapp.com/send?phone=+543413719305&text=Hola, ¿Cómo estas? Quisiera mas información sobre el producto *${producto.prod_nombre}*`
		);
	};
	return (
		<Card
			sx={{
				maxWidth: { xs: 168, sm: 182, md: 226, lg: 292 },
				width: { xs: 168, sm: 182, md: 226, lg: 292 },
				minHeight: { xs: 256, sm: 272, lg: 320 },
				maxHeight: { xs: 280, lg: 320 },
				backgroundColor: "#d0cd9466",
			}}
		>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				overflow="hidden"
				sx={{ height: { lg: "40%" } }}
			>
				<CardMedia
					component="img"
					image={
						producto.prod_imagen !== null
							? `${dmartinaAPI}/${producto.prod_imagen}`
							: placeholder
					}
					alt={producto.prod_nombre}
					sx={{ height: { xs: 110 }, objectFit: "contain" }}
				/>
			</Box>
			<CardContent
				sx={{
					minHeight: 160,
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					px: 0,
					height: { lg: "60%" },
				}}
			>
				<Box>
					<Typography
						variant="p"
						component="p"
						fontSize={14}
						textAlign="center"
						sx={{
							fontSize: { xs: "body2" },
							fontWeight: { xs: 500 },
							px: { lg: 2 },
						}}
					>
						{producto.prod_nombre}
					</Typography>
				</Box>
				<Box
					sx={{
						px: { xs: 1 },
					}}
				>
					<Button
						variant="contained"
						fullWidth
						startIcon={<WhatsApp />}
						sx={{
							mb: 1,
							fontSize: { xs: "button" },
						}}
						onClick={sendWhatsapp}
					>
						Whatsapp
					</Button>
					<Button
						fullWidth
						variant="text"
						onClick={navigateToProduct}
						sx={{
							fontSize: { xs: "button" },
							fontWeight: { xs: 500 },
						}}
					>
						Detalles
					</Button>
				</Box>
			</CardContent>
		</Card>
	);
};
