import { WhatsApp } from "@mui/icons-material";
import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { changeCurrentPath } from "../../slices/location/locationSlice";
import ArrowIcon from "@mui/icons-material/ArrowBack";
import {
	ProductDetailCarousel,
	ProductDetailImage,
	ProductDetails,
} from "../../components/ui/product";
import {
	getProducto,
	productoInitialState,
} from "../../slices/producto/productoSlice";
import { ProductLoader } from "../../components/ui/loader/ProductLoader";
import { PageSEO } from "../../components/seo/PageSEO";
import { changeToastStatus } from "../../slices/toast/toastSlice";
import { userTracker } from "../../helper/userClickTracker";

const ProductoPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const url = window.location.href;

	const { pathname } = useLocation();

	// Estado para controlar el loading del UI
	const [isProductLoading, setIsProductLoading] = useState(true);

	// Recupera el slug en el URL actual
	const productSlug = pathname.split("/")[2];

	const { seo, producto, relacionado } = useSelector(
		(state) => state.productoReducer
	);

	// Funcion para navegar al inicio
	const navigateBack = () => {
		dispatch(productoInitialState());
		navigate("/");
	};
	// Funcion para enviar Whatsapp de consulta
	const sendWhatsapp = () => {
		userTracker(url, producto.nombre, "Click en WhatsApp");
		window.open(
			`https://api.whatsapp.com/send?phone=+543413719305&text=Hola, ¿Cómo estas? Quisiera mas información sobre el producto *${producto.nombre}*`
		);
	};

	useEffect(() => {
		dispatch(changeCurrentPath(pathname.split("")));
	}, []); //eslint-disable-line

	useEffect(() => {
		dispatch(getProducto(productSlug)).then(({ payload }) => {
			if (payload.acceso) {
				dispatch(changeToastStatus(true));

				setTimeout(() => {
					navigateBack();
				}, 2000);
			} else {
				setIsProductLoading(false);
			}
		});
	}, []); //eslint-disable-line

	return (
		<>
			<PageSEO seo={seo} />
			<Box
				sx={{
					display: { sm: "flex" },
					alignItems: { sm: "center" },
					justifyContent: { sm: "center" },
					flexDirection: { sm: "column" },
					width: { xs: "100%" },
				}}
				className="animate__animated animate__fadeIn"
			>
				<Box
					sx={{
						width: { sm: "70%" },
						display: { sm: "flex" },
						alignItems: { sm: "center" },
						justifyContent: { sm: "center" },
						flexDirection: { sm: "column" },
					}}
				>
					{/* CARD */}

					<Box
						sx={{ px: { xs: 1 } }}
						className="animate__animated animate__fadeIn"
					>
						{!isProductLoading && (
							<Box
								display="flex"
								sx={{
									my: { xs: 1 },
								}}
							>
								<IconButton
									sx={{
										width: { sm: 48, lg: 134 },
										py: { sm: 1.5 },
										backgroundColor: "#fff",
										borderRadius: "4px",
										boxShadow:
											"0 1px 1px 0 rgba(0,0,0,.20), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
										display: { sm: "flex" },
										alignItems: { sm: "center" },
										justifyContent: { sm: "center" },
										cursor: "pointer",
									}}
									onClick={navigateBack}
								>
									<ArrowIcon />
								</IconButton>
								<Box
									sx={{
										backgroundColor: "#fff",
										borderRadius: "4px",
										boxShadow:
											"0 1px 1px 0 rgba(0,0,0,.20), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
										display: { xs: "flex" },
										alignItems: { xs: "center" },
										flexGrow: { xs: 1 },
										ml: { xs: 1, lg: 2 },
										pl: { xs: 2 },
									}}
								>
									<Typography
										sx={{
											fontSize: { xs: "body1" },
										}}
										component="h1"
									>
										Detalles
									</Typography>
								</Box>
							</Box>
						)}

						{isProductLoading ? (
							<Box
								sx={{
									height: { xs: "100%" },
									width: { xs: "100%" },
								}}
							>
								<ProductLoader text="Cargando producto..." />
							</Box>
						) : (
							<>
								<Card
									sx={{
										backgroundColor: "#d0cd9466",
										mt: { xs: 2, sm: 3 },
										display: { lg: "flex" },
										minHeight: { xs: 200, lg: 512 },
										width: { sm: 472, lg: 924 },
									}}
								>
									<ProductDetailImage imagen={producto.imagen} />

									<Box>
										<ProductDetails
											nombre={producto.nombre}
											descripcion={producto.descripcion}
										/>

										<Box sx={{ px: { xs: 1 }, mt: { xs: 1 } }}>
											<Button
												variant="contained"
												fullWidth
												startIcon={<WhatsApp />}
												sx={{
													mb: 1,
													fontSize: "button",
												}}
												onClick={sendWhatsapp}
											>
												Whatsapp
											</Button>
										</Box>
									</Box>
								</Card>
								{/* CARRUSEL */}

								{relacionado.length >= 1 && (
									<ProductDetailCarousel relacionado={relacionado} />
								)}
							</>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default ProductoPage;
