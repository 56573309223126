import { useEffect } from "react";
import { Box } from "@mui/material";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const markerIcon = L.icon({
	iconUrl: icon,
	iconRetinaUrl: icon,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [0, -35],
	shadowUrl: iconShadow,
	shadowSize: [41, 41],
	shadowAnchor: [12, 41],
});

export const ContactMap = () => {
	useEffect(() => {
		const map = L.map("map").setView([-32.937945, -60.676578], 13);

		L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
			attribution:
				'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
			maxZoom: 18,
		}).addTo(map);

		L.marker([-32.94264961523265, -60.68808053554998], {
			icon: markerIcon,
		}).addTo(map);

		return () => {
			map.remove();
		};
	}, []);
	return (
		<Box
			sx={{
				height: { xs: 300, sm: 350, md: 400, lg: 500 },
			}}
			id="map"
		/>
	);
};
