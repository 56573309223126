import { Box, Typography } from "@mui/material";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

export const ProductNotFound = () => {
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
			sx={{ mt: 3 }}
			className="animate__animated animate__fadeIn"
		>
			<ErrorRoundedIcon
				sx={{
					width: { xs: 50 },
					height: { xs: 50 },
					color: "#ffb319",
				}}
			/>
			<Typography sx={{ py: 2, fontSize: { xs: "h5" } }} component="h1">
				¡Búsqueda no encontrada!
			</Typography>
			<Typography
				textAlign="center"
				component="p"
				sx={{ fontSize: { xs: "h6" } }}
			>
				No encontramos lo que buscaste.
			</Typography>
			<Typography
				textAlign="center"
				component="p"
				sx={{ fontSize: { xs: "h6" } }}
			>
				Por favor, buscá otro artículo.
			</Typography>
		</Box>
	);
};
