import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosUrl } from "../../api/axiosConfig";
import { API } from "../../api/dmartinaAPI";

const initialState = {
	seo: {},
	url: {},
	productos: [],
	filteredProducts: [],
};

export const getProductos = createAsyncThunk(
	"productos/getProductos",
	async () => {
		const { data } = await axiosUrl.get(API.productos);
		return data;
	}
);

const productosSlice = createSlice({
	name: "productos",
	initialState,
	reducers: {
		// FILTRO DE BUSQUEDA EN INPUT DEL NAVBAR
		inputFilter: (state, { payload }) => {
			return {
				...state,
				filteredProducts: state.productos.filter((producto) =>
					producto.prod_nombre.toLowerCase().includes(payload.toLowerCase())
				),
			};
		},
		// FILTRO DE CATEGORIAS EN SIDEBAR
		categoryFilter: (state, { payload }) => {
			return {
				...state,
				filteredProducts: state.productos.filter((producto) => {
					// SI LA CATEGORIA VIENE COMO UN STRING VACIO DEVUELVE ARRAY ORIGINAL
					if (payload === "") {
						return state.productos;
					} else {
						// CASO CONTRARIO DEVUELVE UN ARRAY DE PRODUCTOS QUE CUMPLAN LA CONDICION -> MISMO ID
						return producto.prod_cate_id === payload;
					}
				}),
			};
		},
	},
	extraReducers: {
		[getProductos.fulfilled]: (state, { payload }) => {
			return {
				...state,
				seo: payload.data.seo,
				url: payload.data.url,
				productos: [...payload.data.producto],
				filteredProducts: [...payload.data.producto],
			};
		},
	},
});

export const { inputFilter, categoryFilter } = productosSlice.actions;
export default productosSlice.reducer;
