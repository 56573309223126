import { Grid } from "@mui/material";
import { CardProduct } from "./CardProduct";

export const CardList = ({ productsToDisplay }) => {
	return (
		<Grid
			container
			spacing={1}
			sx={{
				px: 1,
				py: 1,
			}}
			display="flex"
			justifyContent="center"
		>
			{productsToDisplay.map((producto, index) => (
				<Grid
					item
					xs={6}
					sm={3}
					key={producto.prod_id}
					sx={{
						display: "flex",
						justifyContent: { xs: "center", lg: "unset" },
					}}
				>
					<CardProduct producto={producto} />
				</Grid>
			))}
		</Grid>
	);
};
