import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PageSEO } from "../../components/seo/PageSEO";
import {
	ProductLoader,
	ProductNotFound,
	ProductsContainer,
} from "../../components/ui";
import { changeCurrentPath } from "../../slices/location/locationSlice";
import { changeCurrentPage } from "../../slices/pagination/paginationSlice";
import {
	categoryFilter,
	getProductos,
} from "../../slices/productos/productosSlice";

export const ProductosPage = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	//Estado que verifica la carga de productos en UI
	const [isLoading, setIsLoading] = useState(true);

	// Estado que trae la pagina actual de la paginacion
	const { currentPage } = useSelector((state) => state.paginationReducer);

	// Estado que trae los productos filtrados para mostrar en UI
	const { seo, filteredProducts, productos } = useSelector(
		(state) => state.productosReducer
	);

	const { categoryName, isSearching, categoryID } = useSelector(
		(state) => state.searchReducer
	);

	// Variables para la paginacion
	const startIndex = (currentPage - 1) * 8;
	const endIndex = startIndex + 8;
	const productsToDisplay = filteredProducts.slice(startIndex, endIndex);

	// Funcion que cambia el valor de la pagina actual de la paginacion al disparar la accion
	const changePage = (e, value) => {
		dispatch(changeCurrentPage(value));
	};

	useEffect(() => {
		//Realiza una peticion para traer productos si el usuario escribe un producto en url y luego selecciona una categoria
		if (!productos.length && categoryID && categoryName) {
			dispatch(getProductos()).then((res) => {
				dispatch(categoryFilter(categoryID));
				setIsLoading(false);
			});
		}

		// Realiza una llamada a la API para traer todos los productos si no hay id seleccionado
		if (categoryID === "") {
			dispatch(getProductos()).then((res) => {
				setIsLoading(false);
			});
		} else {
			// Filtra los productos si el usuario selecciono una categoria
			dispatch(categoryFilter(categoryID));
			setIsLoading(false);
		}
	}, []); //eslint-disable-line

	useEffect(() => {
		dispatch(changeCurrentPath(pathname.split("")));
	}, []); //eslint-disable-line

	return (
		<>
			<PageSEO seo={seo} />
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				sx={{ height: "100%", width: "100%" }}
			>
				<Box
					flexDirection="column"
					display="flex"
					justifyContent="space-between"
					sx={{
						width: { xs: "100%", lg: "90%" },
						height: "100%",
						flexGrow: { sm: 1 },
					}}
				>
					{/* Si esta cargando (isLoading) -> muestra el Loader
					Si finalizo la carga y no hay productos (!isLoading && !productos) -> muestra mensaje de error
					Si finalizo la carga y hay productos (!isLoading && productos) -> muestra lista de productos
					*/}
					{isLoading ? (
						<ProductLoader />
					) : !productsToDisplay.length ? (
						<ProductNotFound />
					) : (
						<ProductsContainer
							changePage={changePage}
							productsToDisplay={productsToDisplay}
							currentPage={currentPage}
							filteredProducts={filteredProducts}
							isSearching={isSearching}
							categoryName={categoryName}
						/>
					)}
				</Box>
			</Box>
		</>
	);
};
