import { Box, CardMedia } from "@mui/material";
import { dmartinaAPI } from "../../../api/dmartinaAPI";
import Carousel from "react-material-ui-carousel";
import placeholder from "../../../images/placeholder.svg";

export const ProductDetailImage = ({ imagen }) => {
	return (
		<>
			{imagen?.length > 1 ? (
				<Box sx={{ width: { lg: "100%" } }}>
					<Carousel
						interval={1000}
						duration={250}
						animation="slide"
						autoPlay={false}
						navButtonsAlwaysVisible
						indicators={false}
						sx={{ width: { lg: 440 } }}
					>
						{imagen.map(({ ruta }, index) => (
							<CardMedia
								key={index}
								component="img"
								image={imagen ? `${dmartinaAPI}/${ruta}` : placeholder}
								sx={{ height: { xs: 256, lg: 512 } }}
							/>
						))}
					</Carousel>
				</Box>
			) : (
				<CardMedia
					component="img"
					image={imagen ? `${dmartinaAPI}/${imagen[0]?.ruta}` : placeholder}
					sx={{ height: { xs: 256, lg: 512 }, width: { lg: 440 } }}
				/>
			)}
		</>
	);
};
