import { useDispatch, useSelector } from "react-redux";
import { inputFilter } from "../../../slices/productos/productosSlice";
import { useEffect } from "react";
import {
	categoryInitialState,
	userSearch,
} from "../../../slices/search/searchSlice";
import { NavbarMobile } from "./NavbarMobile";
import { NavbarDesktop } from "./NavbarDesktop";
import { changeCurrentPage } from "../../../slices/pagination/paginationSlice";

export const Navbar = ({
	isSearchOpen,
	searchItem,
	toggleDrawer,
	inputSearch,
}) => {
	const dispatch = useDispatch();

	const { currentPath } = useSelector((state) => state.locationReducer);

	// Funcion que dipara una accion que guarda el input del usuario
	const handleUserSearch = (e) => {
		dispatch(changeCurrentPage(1));
		dispatch(categoryInitialState());
		dispatch(userSearch(e.target.value));
	};

	// Dispara un efecto que filtra el array de productos por el input enviado
	useEffect(() => {
		dispatch(inputFilter(inputSearch));
	}, [inputSearch]); //eslint-disable-line

	return (
		<>
			<NavbarMobile
				toggleDrawer={toggleDrawer}
				isSearchOpen={isSearchOpen}
				handleUserSearch={handleUserSearch}
				inputSearch={inputSearch}
				searchItem={searchItem}
				currentPath={currentPath}
			/>

			<NavbarDesktop
				isSearchOpen={isSearchOpen}
				handleUserSearch={handleUserSearch}
				inputSearch={inputSearch}
				searchItem={searchItem}
				currentPath={currentPath}
			/>
		</>
	);
};
