import { Box, Divider, Typography } from "@mui/material";
import { ProductCarousel } from "../carousel/ProductCarousel";

export const ProductDetailCarousel = ({ relacionado }) => {
	return (
		<Box
			sx={{
				width: { xs: "100%" },
				display: { lg: "flex" },
				alignItems: { lg: "center" },
				justifyContent: { lg: "center" },
			}}
		>
			<Box
				sx={{
					py: { xs: 2 },
					px: { xs: 1 },
					mb: { xs: 3 },
					mt: { xs: 2 },
					backgroundColor: "#fff",
					borderRadius: "4px",
					boxShadow: "0 11px 15px 0 rgba(0,0,0,.20)",
					width: { xs: "100%", lg: "60%" },
				}}
			>
				<Typography sx={{ mb: { xs: 1 } }}>Productos relacionados</Typography>
				<Divider />

				<Box sx={{ mt: { xs: 2 } }}>
					<ProductCarousel relacionado={relacionado} />
				</Box>
			</Box>
		</Box>
	);
};
