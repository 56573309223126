import { Box } from "@mui/material";

export const SidebarDesktop = ({ children }) => {
	return (
		<Box
			sx={{
				display: { xs: "none", lg: "block" },
				width: { lg: "100%" },
				height: { lg: "100%" },
				borderRight: "1px solid #0000001e",
				background: "#fff",
			}}
		>
			{children}
		</Box>
	);
};
