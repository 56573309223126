import { Box, Card, CardContent, Typography } from "@mui/material";
import RocketIcon from "@mui/icons-material/RocketLaunchRounded";

export const AboutCard = ({ descripcion }) => {
	return (
		<Card
			sx={{
				backgroundColor: "#d0cd9466",
				my: { xs: 2 },
			}}
		>
			<CardContent
				sx={{
					minHeight: 160,
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				{/* Titulo */}
				<Box
					sx={{
						display: { xs: "flex" },
						alignItems: { xs: "center" },
						justifyContent: { xs: "center" },
						mb: { xs: 2 },
					}}
				>
					<RocketIcon sx={{ width: { xs: 48 }, height: { xs: 48 } }} />
					<Typography
						component="h1"
						sx={{
							fontSize: { xs: "h5" },
							fontWeight: { xs: 600 },
							pl: { xs: 2 },
							mr: { xs: 3 },
						}}
					>
						Sobre nosotros
					</Typography>
				</Box>

				{/* Descripcion */}
				<Typography
					variant="p"
					component="p"
					fontSize={14}
					textAlign="center"
					sx={{
						fontSize: { xs: "body2" },
						textAlign: { xs: "left" },
						fontWeight: { xs: 500 },
					}}
					dangerouslySetInnerHTML={{ __html: descripcion }}
				/>
			</CardContent>
		</Card>
	);
};
