import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	categoryID: "",
	categoryName: "Productos destacados",
	inputSearch: "",
	isSearching: false,
};

const searchSlice = createSlice({
	name: "search",
	initialState,
	reducers: {
		userSearch: (state, { payload }) => {
			return {
				...state,
				inputSearch: payload,
				isSearching: true,
			};
		},
		categorySearch: (state, { payload }) => {
			if (payload.id === state.categoryID) {
				return {
					...state,
					categoryID: "",
					categoryName: "Productos destacados",
				};
			} else {
				return {
					...state,
					categoryID: payload.id,
					categoryName: payload.name,
				};
			}
		},
		inputInitialState: (state, action) => {
			return {
				...state,
				inputSearch: "",
				isSearching: false,
			};
		},
		categoryInitialState: (state, action) => {
			return {
				...state,
				categoryID: "",
				categoryName: "Productos destacados",
			};
		},
	},
});

export const {
	userSearch,
	categorySearch,
	inputInitialState,
	categoryInitialState,
} = searchSlice.actions;
export default searchSlice.reducer;
