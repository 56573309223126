import { Navigate, Route, Routes } from "react-router-dom";
import { ProductosPage } from "../pages";
import { Suspense } from "react";
import { LazyAcerca, LazyContacto, LazyProducto } from "./lazyRoutes";
import { LazyLoader } from "../components/ui/lazyLoader/LazyLoader";

export const AppRouter = () => {
	return (
		<Suspense fallback={<LazyLoader />}>
			<Routes>
				<Route path="/" element={<ProductosPage />} />
				{/* 
			{url.config.length === 1 && (
				<Route path={`/productos/:slug1`} element={<ProductoPage />} />
				)}
			*/}
				<Route path={`/producto/:slug`} element={<LazyProducto />} />

				<Route path="/sobre-nosotros" element={<LazyAcerca />} />
				<Route path="/contacto" element={<LazyContacto />} />
				<Route path="/*" element={<Navigate to="/" />} />
			</Routes>
		</Suspense>
	);
};
