import { AppBar, Box, Link, Toolbar, Typography } from "@mui/material";

export const Footer = () => {
	return (
		<Box sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
			<AppBar position="static" sx={{ bottom: 0 }}>
				<Toolbar
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						minHeight: "40px !important",
					}}
				>
					<Typography
						sx={{
							fontSize: { xs: "overline" },
							textTransform: { xs: "uppercase" },
						}}
					>
						®️2023 - desarrollado por
						<Link
							color="#fff"
							sx={{ pl: 0.5 }}
							target="_blank"
							href="http://www.desusweb.com/"
							rel="noopener"
						>
							desusweb
						</Link>
					</Typography>
				</Toolbar>
			</AppBar>
		</Box>
	);
};
