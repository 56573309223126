import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosUrl } from "../../api/axiosConfig";
import { API } from "../../api/dmartinaAPI";

const initialState = {
	categorias: [],
	
};

export const getCategorias = createAsyncThunk(
	"categorias/getCategorias",
	async () => {
		const { data } = await axiosUrl.get(API.categorias);
		return data;
	}
);

const categoriasSlice = createSlice({
	name: "categorias",
	initialState,
	reducers: {},
	extraReducers: {
		[getCategorias.fulfilled]: (state, { payload }) => {
			return {
				...state,
				categorias: [...payload.data],
			};
		},
	},
});

// export const {} = productoSlice.actions;
export default categoriasSlice.reducer;
