import { Drawer } from "@mui/material";

export const SidebarMobile = ({ isDrawerOpen, toggleDrawer, children }) => {
	return (
		<Drawer
			anchor={"left"}
			open={isDrawerOpen.left}
			onClose={toggleDrawer(false)}
			sx={{ display: { lg: "none" } }}
		>
			{children}
		</Drawer>
	);
};
