import { Box, CircularProgress, Typography } from "@mui/material";

export const ProductLoader = ({ text = "Cargando productos..." }) => {
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
			sx={{ pt: 3 }}
		>
			<CircularProgress sx={{ color: "#241623" }} />
			<Typography component="p" sx={{ pt: 1, pl: 2, fontSize: "h6" }}>
				{text}
			</Typography>
		</Box>
	);
};
