import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosUrl } from "../../api/axiosConfig";
import { API } from "../../api/dmartinaAPI";

const initialState = {
	seo: {},
	titulo: "",
	descripcion: "",
};

export const getContactoData = createAsyncThunk(
	"contacto/getContacto",
	async () => {
		const { data } = await axiosUrl.get(API.contacto);
		return data;
	}
);

const contactoSlice = createSlice({
	name: "contacto",
	initialState,
	reducers: {},
	extraReducers: {
		[getContactoData.fulfilled]: (state, { payload }) => {
			return {
				...state,
				descripcion: payload.data.descripcion,
				titulo: payload.data.titulo,
				seo: payload.data.seo,
			};
		},
	},
});

export default contactoSlice.reducer;
