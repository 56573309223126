import { Box, Divider, IconButton, List } from "@mui/material";
import { SidebarCategories, SidebarLinks } from "./";
import CloseIcon from "@mui/icons-material/CloseRounded";

export const SidebarList = ({
	toggleDrawer,
	categorias,
	categorySelected,
	categoryID,
	currentPath,
	pathname,
	setIsSearchOpen,
}) => (
	<Box>
		<List>
			<Box
				alignItems="center"
				justifyContent="end"
				sx={{ pl: 2, pt: 0.5, display: { xs: "flex", lg: "none" } }}
			>
				<IconButton
					onClick={toggleDrawer(false)}
					onKeyDown={toggleDrawer(false)}
				>
					<CloseIcon />
				</IconButton>
			</Box>

			{/* LINKS */}
			<SidebarLinks
				toggleDrawer={toggleDrawer}
				pathname={pathname}
				setIsSearchOpen={setIsSearchOpen}
			/>

			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				sx={{ my: 2 }}
			>
				<Divider sx={{ width: "80%" }} />
			</Box>

			{/* CATEGORIAS */}
			<SidebarCategories
				categorias={categorias}
				categorySelected={categorySelected}
				toggleDrawer={toggleDrawer}
				categoryID={categoryID}
			/>
		</List>
	</Box>
);
