import { CardContent, Typography } from "@mui/material";

export const ProductDetails = ({ nombre, descripcion }) => {
	return (
		<CardContent
			sx={{
				display: { lg: "flex" },
				flexGrow: { lg: 1 },
				flexDirection: { lg: "column" },
				height: { lg: 400 },
			}}
		>
			<Typography component="h1" sx={{ fontSize: { xs: "h5" } }}>
				{nombre}
			</Typography>
			<Typography
				sx={{
					mt: { xs: 3 },
					pt: { xs: 2 },
					pb: { xs: 1 },
					fontSize: { xs: "body2" },
					fontWeight: { xs: 500 },
				}}
			>
				Descripcion del producto:
			</Typography>
			<Typography
				sx={{
					fontSize: { xs: "body2" },
					fontWeight: { xs: 500 },
					mt: { xs: 2 },
				}}
			>
				{descripcion}
			</Typography>
		</CardContent>
	);
};
