import { Pagination, PaginationItem } from "@mui/material";

export const ProductsPagination = ({
	filteredProducts,
	currentPage,
	changePage,
}) => {
	return (
		<Pagination
			count={Math.ceil(filteredProducts.length / 8)}
			variant="outlined"
			shape="rounded"
			page={currentPage}
			onChange={changePage}
			boundaryCount={1}
			defaultPage={currentPage}
			sx={{
				display: "flex",
				justifyContent: "center",
				flexGrow: 1,
				width: "100%",
				pt: 1,
				pb: 2,
				"& div": {
					height: { xs: 32, md: 34 },
					width: { xs: 32, md: 34 },
					fontSize: { xs: "h6", md: "h5" },
					boxShadow:
						"0 1px 1px 0 #00000033, 0 2px 2px 0 #00000023, 0 1px 5px 0 #0000001e",
				},
				"& .MuiPaginationItem-root": {
					"&.Mui-selected": {
						background: "#241623",
						color: "white",
					},
				},
			}}
			renderItem={(item) => (
				<PaginationItem
					{...item}
					sx={{
						height: { xs: 32, md: 34 },
						width: { xs: 32, md: 34 },
						mx: { md: 1 },
						fontSize: { xs: "h6" },
					}}
				/>
			)}
		/>
	);
};
