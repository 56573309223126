import { Box, Typography } from "@mui/material";
import { CardList } from "../card/CardList";
import { ProductsPagination } from "../pagination/ProductsPagination";

export const ProductsContainer = ({
	productsToDisplay,
	changePage,
	currentPage,
	filteredProducts,
	categoryName,
	isSearching,
}) => {
	return (
		<>
			{!isSearching && (
				<Box
					sx={{
						display: { sm: "flex" },
						alignItems: { sm: "center" },
						justifyContent: { sm: "center" },
					}}
				>
					<Box
						className="animate__animated animate__fadeIn"
						sx={{
							backgroundColor: "#fff",
							borderRadius: "4px",
							boxShadow:
								"0 1px 1px 0 rgba(0,0,0,.20), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
							py: { xs: 1.5 },
							pl: { xs: 2 },
							my: { xs: 1 },
							mx: { xs: 1 },
							width: { sm: "50%", md: "40%", lg: "60%" },
						}}
					>
						<Typography
							component="h1"
							sx={{
								fontSize: { xs: "body1" },
							}}
						>
							{categoryName}
						</Typography>
					</Box>
				</Box>
			)}

			<Box
				sx={{
					display: "flex",
					flexGrow: 1,
					width: "100%",
					mt: { xs: isSearching && 3 },
				}}
				className="animate__animated animate__fadeIn"
			>
				{/* Listado de productos */}
				<CardList productsToDisplay={productsToDisplay} />
			</Box>

			{/* Evalua si hay mas de 8 productos en el array, sino no muestra la paginacion */}
			{filteredProducts.length >= 8 && (
				<Box className="animate__animated animate__fadeIn">
					{/* Paginacion */}
					<ProductsPagination
						changePage={changePage}
						currentPage={currentPage}
						filteredProducts={filteredProducts}
					/>
				</Box>
			)}
		</>
	);
};
