import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	Typography,
} from "@mui/material";
import HouseIcon from "@mui/icons-material/HouseRounded";
import AboutIcon from "@mui/icons-material/AutoAwesomeRounded";
import ContactIcon from "@mui/icons-material/MailRounded";
import { useNavigate } from "react-router-dom";
import { productoInitialState } from "../../../slices/producto/productoSlice";
import { useDispatch } from "react-redux";
import { inputInitialState } from "../../../slices/search/searchSlice";

const links = [
	{
		icon: <HouseIcon />,
		text: "Inicio",
		url: "/",
	},
	{
		icon: <AboutIcon />,
		text: "Sobre nosotros",
		url: "/sobre-nosotros",
	},
	{
		icon: <ContactIcon />,
		text: "Contacto",
		url: "/contacto",
	},
];

export const SidebarLinks = ({ toggleDrawer, pathname, setIsSearchOpen }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const navigateTo = (url) => {
		dispatch(productoInitialState());
		dispatch(inputInitialState());
		setIsSearchOpen(false);
		navigate(url);
	};

	return (
		<>
			{links.map((link, index) => (
				<ListItem
					key={index}
					sx={{
						px: 0,
						py: 0.5,
						background: pathname === link.url && "#d0cd9466",
						boxShadow: pathname === link.url && "0 3px 5px 0 #00000033",
					}}
					onClick={toggleDrawer(false)}
				>
					<ListItemButton
						sx={{ pl: 4 }}
						onClick={() => navigateTo(link.url)}
						onKeyDown={toggleDrawer(false)}
					>
						<ListItemIcon key={index}>{link.icon}</ListItemIcon>

						<Typography sx={{ fontSize: { xs: "body2" } }}>
							{link.text}
						</Typography>
					</ListItemButton>
				</ListItem>
			))}
		</>
	);
};
