import { configureStore } from "@reduxjs/toolkit";

import acercaReducer from "../slices/acerca/acercaSlice";
import contactoReducer from "../slices/contacto/contactoSlice";
import productoReducer from "../slices/producto/productoSlice";
import productosReducer from "../slices/productos/productosSlice";
import categoriasReducer from "../slices/categorias/categoriasSlice";
import searchReducer from "../slices/search/searchSlice";
import paginationReducer from "../slices/pagination/paginationSlice";
import locationReducer from "../slices/location/locationSlice";
import toastReducer from "../slices/toast/toastSlice";

export const store = configureStore({
	reducer: {
		acercaReducer,
		searchReducer,
		productoReducer,
		contactoReducer,
		productosReducer,
		categoriasReducer,
		paginationReducer,
		locationReducer,
		toastReducer,
	},
});
