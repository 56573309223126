import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isToastVisible: false,
};

const toastSlice = createSlice({
	name: "toast",
	initialState,
	reducers: {
		changeToastStatus: (state, { payload }) => {
			return {
				...state,
				isToastVisible: payload,
			};
		},
	},
});

export const { changeToastStatus } = toastSlice.actions;
export default toastSlice.reducer;
