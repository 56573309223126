import { useEffect } from 'react';
import CryptoJS from 'crypto-js';


let encrypted = null
const encryptValue = () => {
    return encrypted = CryptoJS.SHA256(Date.now()).toString();
};
encryptValue();

const hasVisited = localStorage.getItem('hasVisited');

export const UserCheck = () => {
    useEffect(() => {
            if (!hasVisited) {
                localStorage.setItem('hasVisited', encrypted);
            }
    }, []);
}

export const ShowCookie = () => {
    return hasVisited;
}
