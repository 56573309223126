import { createTheme } from "@mui/material";

export const dmartinaTheme = createTheme({
	components: {
		MuiCard: {
			styleOverrides: {
				root: {
					boxShadow:
						"0 1px 1px 0 #00000033, 0 2px 2px 0 #00000023, 0 1px 5px 0 #0000001e",
					border: "0.3px solid #bdbdbd",
				},
			},
		},
		MuiTextField: {
			variants: [
				{
					props: { variant: "filled" },
					style: {
						backgroundColor: "#fff",
						borderRadius: "4px",
						// height: 40
					},
				},
			],
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "none",
				},
			},
			variants: [
				{
					props: { variant: "contained" },
					style: {
						backgroundColor: "primary",
						"&:hover": {
							backgroundColor: "#241623b2",
						},
					},
				},
				{
					props: { variant: "text" },
					style: {
						"&:hover": {
							backgroundColor: "#241623a",
						},
					},
				},
			],
		},

		MuiPaginationItem: {
			variants: [
				{
					props: { variant: "outlined" },
					style: {
						backgroundColor: "#fff",
						boxShadow:
							"0 1px 1px 0 #00000033, 0 2px 2px 0 #00000023, 0 1px 5px 0 #0000001e",
						borderRadius: "4px",
					},
				},
			],
		},
	},
	palette: {
		primary: {
			main: "#241623",
		},
		secondary: {
			main: "#A85811",
		},
		danger: {
			main: "#D33030",
		},
		info: {
			main: "#3448f0",
		},
		success: {
			main: "#23a528",
		},
		warning: {
			main: "#ffb319",
		},
		background: {
			default: "#ececec",
		},
	},
	typography: {
		fontFamily: ["Manrope"],
		h1: {
			fontWeight: "400",
			fontSize: 88,
		},
		h2: {
			fontWeight: "300",
			fontSize: 57,
		},
		h3: {
			fontWeight: "normal",
			fontSize: 48,
		},
		h4: {
			fontWeight: "lighter",
			fontSize: 34,
		},

		h5: {
			fontWeight: "400",
			fontSize: 23,
		},
		h6: {
			fontWeight: "400",
			fontSize: 20,
		},
		subtitle1: {
			fontWeight: "300",
			fontSize: 15,
		},
		subtitle2: {
			fontWeight: "400",
			fontSize: 14,
		},
		body1: {
			fontWeight: "400",
			fontSize: 18,
		},
		body2: {
			fontWeight: "400",
			fontSize: 14,
		},
		button: {
			fontWeight: "300",
			fontSize: 14,
		},
		caption: {
			fontWeight: "400",
			fontSize: 12,
		},
		overline: {
			fontWeight: "400",
			fontSize: 12,
		},
	},
});
