import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	currentPage: 1,
};

const paginationSlice = createSlice({
	name: "pagination",
	initialState,
	reducers: {
		changeCurrentPage: (state, { payload }) => {
			//Cambia el valor de currentPage por el enviado en el payload
			//El valor proviene de la paginacion
			return {
				...state,
				currentPage: payload,
			};
		},
	},
});

export const { changeCurrentPage } = paginationSlice.actions;
export default paginationSlice.reducer;
