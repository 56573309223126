import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosUrl } from "../../api/axiosConfig";
import { API } from "../../api/dmartinaAPI";

const initialState = {
	seo: {},
	producto: {},
	relacionado: [],
	acceso: false,
};

export const getProducto = createAsyncThunk(
	"producto/getProducto",
	async (slug) => {
		const { data } = await axiosUrl.get(`${API.producto}/${slug}`);
		return data;
	}
);

const productoSlice = createSlice({
	name: "producto",
	initialState,
	reducers: {
		productoInitialState: (state, action) => {
			return initialState;
		},
	},
	extraReducers: {
		[getProducto.fulfilled]: (state, { payload }) => {
			if (!payload.acceso) {
				return {
					...state,
					seo: payload.data.seo,
					producto: payload.data.producto,
					relacionado: [...payload.data.relacionado],
				};
			} else {
				return {
					...state,
					acceso: true,
				};
			}
		},
	},
});

export const { productoInitialState } = productoSlice.actions;
export default productoSlice.reducer;
