import {
	AppBar,
	Avatar,
	Box,
	IconButton,
	TextField,
	Toolbar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import DmartinaLogo from "../../../images/logo.png";
export const NavbarDesktop = ({
	isSearchOpen,
	inputSearch,
	currentPath,
	searchItem,
	handleUserSearch,
}) => {
	return (
		<Box
			sx={{
				display: { xs: "none", lg: "flex" },
			}}
		>
			<AppBar
				position="static"
				sx={{
					zIndex: (theme) => theme.zIndex.drawer + 1,
					display: { xs: "none", lg: "flex" },
					justifyContent: { lg: "center" },
					width: "100%",
					height: { lg: 80 },
				}}
			>
				<Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
					<Avatar
						className="animate__animated animate__fadeIn"
						alt="Dmartina Logo"
						src={DmartinaLogo}
						sx={{
							height: 68,
							width: 68,
						}}
					/>

					{isSearchOpen && (
						<Box
							sx={{
								width: { lg: "100%" },
								display: { lg: "flex" },
								justifyContent: { lg: "end" },
							}}
						>
							<TextField
								type="text"
								label="Buscar"
								variant="filled"
								size="small"
								className="animate__animated animate__fadeIn"
								onChange={handleUserSearch}
								value={inputSearch}
								sx={{ width: { lg: "87%" } }}
							/>
						</Box>
					)}

					{currentPath.length === 1 && (
						<IconButton
							size="large"
							edge="end"
							color="inherit"
							sx={{ ml: 2 }}
							onClick={searchItem}
							aria-label={isSearchOpen ? "cerrar buscador" : "boton buscar"}
						>
							{isSearchOpen ? <CloseIcon /> : <SearchIcon />}
						</IconButton>
					)}
				</Toolbar>
			</AppBar>
		</Box>
	);
};
