import { ShowCookie } from "./userCheck.js";

export function userTracker(url, producto, clickedButton) {
	window.gtag('set', 'userId', ShowCookie());
	window.gtag("event", clickedButton, {
		event_category: clickedButton,
		event_label: url,
		event_value: 0,
		dimension1: producto,
	});
}
