import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeToastStatus } from "../../../slices/toast/toastSlice";

export const ToastAlert = () => {
	const dispatch = useDispatch();
	const { isToastVisible } = useSelector((state) => state.toastReducer);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(changeToastStatus(false));
	};
	return (
		<Snackbar
			autoHideDuration={3000}
			anchorOrigin={{ vertical: "top", horizontal: "" }}
			open={isToastVisible}
			onClose={handleClose}
			className="animate__animated animate__slideInDown"
		>
			<Alert
				severity="warning"
				sx={{
					backgroundColor: "#ffb319",
					[`& .MuiAlert-icon`]: { color: "#000" },
				}}
			>
				No encontramos la página ingresada, redirigimos al inicio
			</Alert>
		</Snackbar>
	);
};
