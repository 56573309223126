import {
	AppBar,
	Avatar,
	Box,
	IconButton,
	TextField,
	Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/MenuRounded";
import SearchIcon from "@mui/icons-material/SearchRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import DmartinaLogo from "../../../images/logo.png";

export const NavbarMobile = ({
	toggleDrawer,
	isSearchOpen,
	handleUserSearch,
	inputSearch,
	searchItem,
	currentPath,
}) => {
	return (
		<Box sx={{ display: { xs: "flex", lg: "none" } }}>
			<AppBar position="static" sx={{ py: 1, width: "100%" }}>
				<Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ mr: 2, display: { lg: "none" } }}
						onClick={toggleDrawer(true)}
					>
						<MenuIcon />
					</IconButton>
					{!isSearchOpen ? (
						<>
							<Box
								sx={{
									display: "flex",
									flexGrow: 1,
									justifyContent: "center",
								}}
							>
								<Avatar
									className="animate__animated animate__fadeIn"
									alt="Dmartina Logo"
									src={DmartinaLogo}
									sx={{
										height: 60,
										width: 60,
									}}
								/>
							</Box>
							<Box
								sx={{
									width: 50,
									display: currentPath.length > 1 ? "" : "none",
									color: "transparent",
								}}
							>
								x
							</Box>
						</>
					) : (
						<TextField
							type="text"
							label="Buscar"
							fullWidth
							variant="filled"
							size="small"
							className="animate__animated animate__fadeIn"
							onChange={handleUserSearch}
							value={inputSearch}
						/>
					)}
					{currentPath.length === 1 && (
						<IconButton
							size="large"
							edge="end"
							color="inherit"
							sx={{ ml: 2 }}
							onClick={searchItem}
							aria-label={isSearchOpen ? "cerrar buscador" : "boton buscar"}
						>
							{isSearchOpen ? <CloseIcon /> : <SearchIcon />}
						</IconButton>
					)}
				</Toolbar>
			</AppBar>
		</Box>
	);
};
