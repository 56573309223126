import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { dmartinaAPI } from "../../api/dmartinaAPI";

export const PageSEO = ({ seo }) => {
	const { currentUrl } = useSelector((state) => state.locationReducer);

	return (
		<Helmet>
			<title>{seo.titulo}</title>
			<meta name="description" content={seo.meta_descripcion} />

			<meta name="keywords" content={seo.meta_keyword} />
			<meta property="og:title" content={seo.titulo} />
			<meta property="og:description" content={seo.meta_descripcion} />
			<meta property="og:image" content={`${dmartinaAPI}/${seo.meta_imagen}`} />
			<meta property="og:url" content={currentUrl} />
			<meta name="twitter:title" content={seo.titulo} />
			<meta name="twitter:description" content={seo.meta_descripcion} />
			<meta
				name="twitter:image"
				content={`${dmartinaAPI}/${seo.meta_imagen}`}
			/>
			<meta name="twitter:card" content="summary_large_image" />
		</Helmet>
	);
};
