import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	currentPath: [],
	currentUrl: "",
};

const locationSlice = createSlice({
	name: "location",
	initialState,
	reducers: {
		changeCurrentPath: (state, { payload }) => {
			//Cambia el valor de currentPath por el enviado en el payload
			//El valor proviene del path del URL actual
			return {
				...state,
				currentPath: [...payload],
			};
		},
		changeCurrentURL: (state, { payload }) => {
			//Cambia el valor de currentUrl por el enviado en el payload
			//El valor proviene de la URL actual
			return {
				...state,
				currentUrl: payload,
			};
		},
	},
});

export const { changeCurrentPath, changeCurrentURL } = locationSlice.actions;
export default locationSlice.reducer;
